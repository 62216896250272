/* eslint-disable max-params */
export const waitFor = (variable, getDOMOrWindowVariable, callback, reject) => {
  let intervalMS = 0;
  const initInterval = setInterval(() => {
    if (getDOMOrWindowVariable(variable)) {
      clearInterval(initInterval);
      callback();
    } else if (intervalMS > 30000) {
      clearInterval(initInterval);
      reject && reject();
    }
    intervalMS += 100;
  }, 100);
};

export const waitForWindowVariable = (variable, callback, reject) => {
  const getWindowVariable = v => window[v];
  waitFor(variable, getWindowVariable, callback, reject);
};

export const waitForDOM = (selector, callback, reject) => {
  const $getDOMBySelector = s => document.querySelector(s);
  waitFor(selector, $getDOMBySelector, callback, reject);
};

export const waitForImage = (selector, timeout = 7000) => {
  const $image = document.querySelector(selector);
  const isCached = $imageToCheck => {
    const image = new Image();
    image.src = $imageToCheck.currentSrc || $imageToCheck.src;
    return image.complete;
  };
  return new Promise(resolve => {
    if (!$image || isCached($image)) {
      resolve();
    } else {
      $image.addEventListener("load", resolve);
      setTimeout(resolve, timeout);
    }
  });
};
