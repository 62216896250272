const trackRudderstackEvent = (eventType, rest) => {
  window.dataLayer.push({
    event: "rudderstack_event",
    eventData: {
      event_type: eventType,
      ...rest
    }
  });
};

export default trackRudderstackEvent;
