import { waitForWindowVariable } from "common/utils/ui/wait_for";

const delayedEvents = [];

// if window.mixpanel is already available, retrySubmitMixpanelEvents
// will not be called
const retrySubmitMixpanelEvents = (event, properties, options) => {
  if (!delayedEvents.length) {
    waitForWindowVariable("mixpanel", () => {
      // eslint-disable-next-line no-console
      console.log(`Found ${delayedEvents.length} mixpanel event(s) from bucket to submit`);

      while (delayedEvents.length > 0) {
        const [e, prop, opts] = delayedEvents.shift();
        window.mixpanel.track(e, prop, opts);
      }
    });
  }

  delayedEvents.push([event, properties, options]);
};

export default retrySubmitMixpanelEvents;
