import { waitForWindowVariable } from "common/utils/ui/wait_for";
import trackRudderstackEvent from "./track-rudderstack-event";

const delayedEvents = [];

// if window.dataLayer is already available, retrySubmitRudderstackEvents
// will not be called
const retrySubmitRudderstackEvents = (event, properties) => {
  if (!delayedEvents.length) {
    waitForWindowVariable("dataLayer", () => {
      waitForWindowVariable("rudderanalytics", () => {
        // eslint-disable-next-line no-console
        console.log(`Found ${delayedEvents.length} rudderstack event(s) from bucket to submit`);

        while (delayedEvents.length > 0) {
          const [e, prop] = delayedEvents.shift();
          trackRudderstackEvent(e, prop);
        }
      });
    });
  }

  delayedEvents.push([event, properties]);
};

export default retrySubmitRudderstackEvents;
